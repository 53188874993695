import { appHelpers } from '../../../helpers';
import axios from 'axios';

const fetchCompanyMembers = async (
  {
    hsId,
    companyIds,
  }: {
    hsId: string;
    companyIds: Array<number | string>;
  },
  token: string
) => {
  const BODY = {
    hsId: hsId,
    companyIds: companyIds,
  };

  const CONFIG = {
    url: `${appHelpers.getApiUrl()}/api/v2/companies/fetchCompanyMembers`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: BODY,
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error fetching comapny members: ${err.message}`);
    } else {
      throw new Error(`Error fetching company members}`);
    }
  }
};

export { fetchCompanyMembers };
