import { api } from '../../api/apiHandler';

type UploadFileType = {
  file: Record<string, unknown>;
  hsId: string | null | undefined;
  companyId?: string | null;
  uploadType: string;
};

const uploadFileToStorage = async (uploadFileData: UploadFileType) => {
  const { file, hsId, companyId, uploadType } = uploadFileData;

  const folderName = `${hsId}_${companyId ? companyId : ''}`;

  return await api({
    clientName: folderName,
    file: file,
    uploadType: uploadType,
  }).files.uploadFile(); // Doesn't call BE API so doesn't need token
};

export { uploadFileToStorage };
