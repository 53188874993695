import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';
import { useAuth0 } from '@auth0/auth0-react';

const useGetForecasts = (
  searchTerm = '',
  submittedStart = '',
  submittedEnd = ''
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { hsId } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: forecastsData,
    isLoading: isForecastsLoading,
    error: isForecastsError,
    refetch,
    isFetching: isFetchingForecasts,
  } = useQuery(
    ['forecasts', currentPage, searchTerm, submittedStart, submittedEnd],
    async () => {
      if (hsId) {
        const token = await getAccessTokenSilently();
        if (searchTerm) {
          return await api(
            {
              objectName: 'forecasts',
              searchValue: searchTerm,
              hsId: hsId,
              submittedStart: submittedStart,
              submittedEnd: submittedEnd,
            },
            token
          ).forecasts.searchForecasts();
        } else if (submittedStart || submittedEnd) {
          return await api(
            {
              objectName: 'forecasts',
              searchValue: searchTerm,
              hsId: hsId,
              submittedStart: submittedStart,
              submittedEnd: submittedEnd,
            },
            token
          ).forecasts.searchForecasts();
        } else {
          return await api(
            {
              hsId: hsId,
              currentPage: currentPage,
              nextPage: currentPage + 1,
            },
            token
          ).forecasts.getForecasts();
        }
      } else {
        return [];
      }
    },
    { enabled: !!hsId }
  );

  return {
    forecastsData,
    isForecastsLoading,
    isForecastsError,
    refetch,
    isFetchingForecasts,
    currentPage,
    setCurrentPage,
  };
};

export { useGetForecasts };
