import axios from 'axios';

import { appHelpers, ids } from '../../../helpers';
import { INVOICES_PROPERTIES } from '../../../constants/invoicesObjectProperties';

import { InvoicesRequestData } from './types';
import { AssociationRequestData } from '../../../types/associations';

const getInvoices = async (requestData: InvoicesRequestData, token: string) => {
  if (!requestData) {
    throw new Error('Unable to fetch invoices');
  }

  const { hsId, companyId, uid, currentPage, nextPage } = requestData;

  const associationRequestData: AssociationRequestData = {
    objectFromType: null,
    recordId: null,
  };

  const objectIds = ids.getObjectIds();
  const pipelineIds = ids.getPipelineIds();

  if (companyId) {
    associationRequestData.objectFromType = objectIds
      ? objectIds.companies
      : null;
    associationRequestData.recordId = companyId;
  } else {
    associationRequestData.objectFromType = objectIds
      ? objectIds.contacts
      : null;
    associationRequestData.recordId = hsId;
  }

  const BODY = {
    objectType: objectIds ? objectIds.invoices : null,
    properties: INVOICES_PROPERTIES,
    hsId: hsId,
    uid: uid,
    companyId: companyId,
    pipelineId: pipelineIds ? pipelineIds.invoices : null,
    associationData: associationRequestData,
    pagination: {
      currentPage: currentPage || 0,
      nextPage: nextPage || 1,
      perPage: 20,
    },
  };

  const CONFIG = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/invoices/getInvoices`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: BODY,
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error fetching invoices: ${err.message}`);
    } else {
      throw new Error(`Error fetching invoices`);
    }
  }
};

export { getInvoices };
