import axios from 'axios';
import { appHelpers } from '../../../helpers';
import { ids } from '../../../helpers';
import { getAuth } from 'firebase/auth';

type ForecastRequestData = {
  hsId: string | null;
  uid: string | null;
  companyId: string | null;
  contract_id: string | null;
  properties: Array<string> | null;
};

export type CreateAssociation = {
  to: { id: string };
  types: {
    associationCategory: string;
    associationTypeId: number | null;
  }[];
};

type CreateAssociationData = CreateAssociation[];

let associations: CreateAssociationData = [];

const createForecast = async (
  requestData: ForecastRequestData,
  token: string
) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error('User not authenticated');
  }

  if (!requestData.hsId) {
    throw new Error('hsId is required');
  }

  // const accessToken = await currentUser.getIdToken();
  const objectIds = ids.getObjectIds();
  const associationIds = ids.getAssociationIds();

  if (requestData.companyId) {
    associations = [
      {
        to: { id: requestData.companyId },
        types: [
          {
            associationCategory: 'USER_DEFINED',
            associationTypeId: associationIds
              ? associationIds.gen_services_forecast_asset_to_company
              : null,
          },
        ],
      },
    ];
  } else {
    associations = [
      {
        to: { id: requestData.hsId },
        types: [
          {
            associationCategory: 'USER_DEFINED',
            associationTypeId: associationIds
              ? associationIds.gen_services_forecast_asset_to_contact
              : null,
          },
        ],
      },
    ];
  }

  if (requestData.contract_id) {
    associations.push({
      to: { id: requestData.contract_id },
      types: [
        {
          associationCategory: 'USER_DEFINED',
          associationTypeId: associationIds
            ? associationIds.gen_services_forecast_asset_to_contract
            : null,
        },
      ],
    });
  }

  const BODY = {
    objectType: objectIds ? objectIds.forecasts : null,
    hsId: requestData.hsId,
    properties: requestData.properties,
    associations: associations,
  };

  const CONFIG = {
    url: `${appHelpers.getApiUrl()}/api/v2/forecasts/createForecast`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: BODY,
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error creating forecast: ${err.message}`);
    } else {
      throw new Error(`Error creating forecast`);
    }
  }
};

export { createForecast };
