import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';
import { useAuth0 } from '@auth0/auth0-react';

const useGetAllForecasts = () => {
  const { hsId } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: forecastsData,
    isLoading: isForecastsLoading,
    error: isForecastsError,
    refetch,
    isFetching: isFetchingForecasts,
  } = useQuery(
    ['all-forecasts'],
    async () => {
      if (hsId) {
        const token = await getAccessTokenSilently();
        return await api(
          {
            hsId: hsId,
          },
          token
        ).forecasts.getAllForecasts();
      } else {
        return [];
      }
    },
    { enabled: !!hsId }
  );

  return {
    forecastsData,
    isForecastsLoading,
    isForecastsError,
    refetch,
    isFetchingForecasts,
  };
};

export { useGetAllForecasts };
