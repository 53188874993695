/**
 * gen-services - apiHandler.js
 * API Handler - handles all the API requests to Firebase / HubSpot
 * HubSpot requests use Firebase Cloud Functions
 * Firebase requests use the Firebase SDK
 * @author Kris Bradbury
 */

// V2 API
import { documents } from './v2';
import { files } from './v2';
import { invoices } from './v2';
import { statements } from './v2';
import { forecasts } from './v2';
import { tickets } from './v2';
import { messages } from './v2';
import { posts } from './v2';
import { users } from './v2';
import { auth } from './v2';
import { companies } from './v2';
import { email } from './v2';
import { contracts } from './v2';
import { accounts } from './v2/';
import { access } from './v2';
import { app } from './v2';
import { search } from './v2';
import { customObjects } from './v2';

/**
 * Handles updated V2 API requests
 */
const api = (requestData, token) => {
  return {
    documents: {
      getDocuments: () => documents.getDocuments(requestData, token),
      createDocument: () => documents.createDocument(requestData, token),
      searchDocuments: () => documents.searchDocuments(requestData, token),
    },
    files: {
      uploadFile: () => files.uploadFile(requestData, token),
    },
    invoices: {
      getInvoices: () => invoices.getInvoices(requestData, token),
      getAllInvoices: () => invoices.getAllInvoices(requestData, token),
      getInvoice: () => invoices.getInvoice(requestData, token),
      createInvoice: () => invoices.createInvoice(requestData, token),
      searchInvoices: () => invoices.searchInvoices(requestData, token),
    },
    statements: {
      getStatements: () => statements.getStatements(requestData, token),
      getAllStatements: () => statements.getAllStatements(requestData, token),
      getStatement: () => statements.getStatement(requestData, token),
      getStatementInvoices: () =>
        statements.getStatementInvoices(requestData, token),
      getStatementTickets: () =>
        statements.getStatementTickets(requestData, token),
      searchStatements: () => statements.searchStatements(requestData, token),
    },
    forecasts: {
      getForecasts: () => forecasts.getForecasts(requestData, token),
      getAllForecasts: () => forecasts.getAllForecasts(requestData, token),
      getForecast: () => forecasts.getForecast(requestData, token),
      createForecast: () => forecasts.createForecast(requestData, token),
      searchForecasts: () => forecasts.searchForecasts(requestData, token),
    },
    tickets: {
      getTickets: () => tickets.getTickets(requestData, token),
      getSingleTicket: () => tickets.getSingleTicket(requestData, token),
      getSingleTicketEmails: () =>
        tickets.getSingleTicketEmails(requestData, token),
      createTicket: () => tickets.createTicket(requestData, token),
      updateTicket: () => tickets.updateTicket(requestData, token),
      createTicketAssociation: () =>
        tickets.createTicketAssociation(requestData, token),
      searchTickets: () => tickets.searchTickets(requestData, token),
    },
    messages: {
      getMessages: () => messages.getMessages(requestData, token),
      updateMessageStatus: () =>
        messages.updateMessageStatus(requestData, token),
    },
    posts: {
      getPosts: () => posts.getPosts(requestData, token),
    },
    users: {
      getHubspotUser: () => users.getHubspotUser(requestData, token),
      createHubspotUser: () => users.createHubspotUser(requestData, token),
      updateFirestoreUser: () => users.updateFirestoreUser(requestData, token),
      updateHubspotUser: () => users.updateHubspotUser(requestData, token),
      fetchFirebaseUser: () => users.fetchFirebaseUser(requestData, token),
      setFirestoreUser: () => users.setFirestoreUser(requestData, token),
      fetchEmailVerifyStatus: () =>
        users.fetchEmailVerifyStatus(requestData, token),
      verifyFirebaseUser: () => users.verifyFirebaseUser(requestData, token),
      generateResetLink: () => users.generateResetLink(requestData, token),
    },
    auth: {
      createFirebaseEmailUser: () =>
        auth.createFirebaseEmailUser(requestData, token),
      getHubspotUser: () => auth.getHubspotUser(requestData, token),
      getFirebaseUser: () => auth.getFirebaseUser(requestData, token),
      addUserToFirestore: () => auth.addUserToFirestore(requestData, token),
    },
    companies: {
      fetchCompanyMembers: () =>
        companies.fetchCompanyMembers(requestData, token),
    },
    email: {
      createEmail: () => email.createEmail(requestData, token),
    },
    contracts: {
      getContracts: () => contracts.getContracts(requestData, token),
    },
    accounts: {
      fetchUsersAccounts: () => accounts.fetchUsersAccounts(requestData, token),
    },
    access: {
      verifyContactAccess: () => access.verifyContactAccess(requestData, token),
    },
    app: {
      getAppSettings: () => app.getAppSettings(requestData, token),
    },
    search: {
      searchObjects: () => search.searchObjects(requestData, token),
    },
    customObjects: {
      updateCustomObject: () =>
        customObjects.updateCustomObject(requestData, token),
    },
  };
};

export { api };
