import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';
import { useAuth0 } from '@auth0/auth0-react';

const useGetTicketEmails = () => {
  const { id } = useParams();
  const { hsId } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['singleTicketEmails', id],
    queryFn: async () => {
      if (id) {
        const token = await getAccessTokenSilently();
        return await api(
          {
            ticketId: id,
            hsId: hsId,
          },
          token
        ).tickets.getSingleTicketEmails();
      } else {
        return [];
      }
    },
    enabled: !!hsId,
  });

  return {
    singleTicketEmailData: data,
    singleTicketEmailError: error,
    singleTicketEmailLoading: isLoading,
    singleTicketEmailRefetch: refetch,
  };
};

export { useGetTicketEmails };
