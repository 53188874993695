import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';
import { useAuth0 } from '@auth0/auth0-react';

const useGetCompanyMembers = () => {
  const { hsId, companyIds } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const handleGetCompanyMembers = async () => {
    const token = await getAccessTokenSilently();
    return await api(
      {
        hsId: hsId,
        companyIds: companyIds,
      },
      token
    ).companies.fetchCompanyMembers();
  };

  const { data } = useQuery({
    queryKey: ['comapnyMembers'],
    queryFn: handleGetCompanyMembers,
    enabled: !!hsId,
  });

  return { data };
};

export { useGetCompanyMembers };
