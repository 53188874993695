import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';
import { useAuth0 } from '@auth0/auth0-react';

const useGetAllInvoices = () => {
  const { hsId } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: invoicesData,
    isLoading: isInvoicesLoading,
    error: isInvoicesError,
    refetch: invoiceRefetch,
    isFetching: isFetchingInvoices,
  } = useQuery(
    ['all-invoices'],
    async () => {
      if (hsId) {
        const token = await getAccessTokenSilently();
        return await api(
          {
            hsId: hsId,
          },
          token
        ).invoices.getAllInvoices();
      } else {
        return [];
      }
    },
    { enabled: !!hsId, keepPreviousData: true }
  );

  return {
    invoicesData,
    isInvoicesLoading,
    isInvoicesError,
    invoiceRefetch,
    isFetchingInvoices,
  };
};

export { useGetAllInvoices };
