import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

import { useParams } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';

const useGetStatementTickets = () => {
  const { hsId } = useUserContext();
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading, error, refetch } = useQuery(
    ['Statement Tickets', id],
    async () => {
      if (hsId) {
        const token = await getAccessTokenSilently();
        return await api(
          {
            objectType: 'statements',
            hsId: hsId,
            recordId: id,
          },
          token
        ).tickets.getTickets();
      } else {
        return [];
      }
    }
  );

  return {
    tickets: data,
    ticketsLoading: isLoading,
    ticketsError: error,
    refetchTickets: refetch,
  };
};

export { useGetStatementTickets };
