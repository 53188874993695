import styled from 'styled-components';

export const FormSuccessStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4.8rem;

  h3 {
    color: var(--nbs-blue);
    font-size: 2rem;
    font-weight: bold;
  }

  p {
    margin: 0;
    width: 100%;
    color: var(--nbs-black);
    text-align: center;
  }
  button {
    width: 100%;
    display: inline-flex;
    color: #fff;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    padding: 1rem 1.5rem;
    margin-right: 0;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.8;
    text-decoration: none !important;
    text-transform: uppercase;
    background-color: #0a5ca9;
    border: solid 0.2rem #0a5ca9;
    border-radius: 999px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
      color: var(--white);
      border-color: var(--white);
      box-shadow: 0.2rem 0.2rem 1rem rgb(10 92 169 / 42%);
      background-color: #524388;
    }
  }
`;
