import axios from 'axios';
import { appHelpers } from '../../../../helpers';
import { ids } from '../../../../helpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadFileToStorage } from '../../../../services/storage';
import { generateDocumentAssociations } from '../services/generateDocumentAssociations';

import { DocumentData } from '../types';
import { useAuth0 } from '@auth0/auth0-react';

const addDocuments = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const handleAddDocument = async (documentData: DocumentData) => {
    const objectIds = ids.getObjectIds();

    const uploadFileData = {
      file: documentData.attachment_documentsForm,
      hsId: documentData.hsId,
      companyId: null,
      uploadType: 'documents',
    };

    // 1. Upload to Firebase
    const fileUrl = await uploadFileToStorage(uploadFileData);

    // 2. Create Association Data
    const associationData = {
      contractId: documentData.contract_id,
      hsId: documentData.hsId,
      companyId: null,
    };

    const associations = generateDocumentAssociations(associationData);

    // 3. Add to HubSpot
    const docProperties = {
      document_reference: documentData.document_reference,
      document_attachment: fileUrl,
      file_name: documentData.attachment_documentsForm.name,
      document_attachment_type: documentData.attachment_documentsForm.type,
    };

    const body = {
      objectType: objectIds ? objectIds.documents : null,
      hsId: documentData.hsId,
      properties: docProperties,
      associations: associations,
    };

    const token = await getAccessTokenSilently();
    const config = {
      url: `${appHelpers.getApiUrl()}/api/v2/documents/createDocument`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: body,
    };

    try {
      const { data } = await axios(config);

      return {
        status: 'error',
        message: 'Document uploaded',
        data,
      };
    } catch (err) {
      console.error(err);
      return {
        status: 'error',
        message:
          'There was a problem uploading your document, please try again',
      };
    }
  };

  const mutation = useMutation({
    mutationFn: handleAddDocument,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['documents'] });
    },
  });

  const handleCreateDocument = (documentData: DocumentData) => {
    mutation.mutate(documentData);
  };

  return { handleCreateDocument };
};

export { addDocuments };
