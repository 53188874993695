import { useUserContext } from '../../providers/user/useUserContext';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useAuth0 } from '@auth0/auth0-react';

const useGetHubspotUser = () => {
  const { hsId, user } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const fetchHubspotUser = async () => {
    const token = await getAccessTokenSilently();
    return await api(
      {
        email: user?.email,
        hsId: hsId,
      },
      token
    ).users.getHubspotUser();
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['user'],
    queryFn: fetchHubspotUser,
    enabled: !!hsId,
  });
  return { hsUserData: data, isLoadingUser: isLoading, userError: error };
};

export { useGetHubspotUser };
