import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';
import { useAuth0 } from '@auth0/auth0-react';

const useGetContracts = () => {
  const { hsId } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const handleGetContracts = async () => {
    const token = await getAccessTokenSilently();
    return await api(
      {
        hsId: hsId,
      },
      token
    ).contracts.getContracts();
  };

  const {
    data: contractsData,
    isLoading: isContractsLoading,
    error: isContractsError,
  } = useQuery({
    queryKey: ['contracts'],
    queryFn: handleGetContracts,
    enabled: !!hsId,
  });

  return {
    contractsData,
    isContractsLoading,
    isContractsError,
  };
};

export { useGetContracts };
