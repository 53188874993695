import axios from 'axios';
import { appHelpers } from '../../../helpers';
import { ids } from '../../../helpers';

import { CreateDocAssociationRequestData, CreateDocRequestData } from './types';

let associations: CreateDocAssociationRequestData = [];

const createDocument = async (
  requestData: CreateDocRequestData,
  token: string
) => {
  if (!requestData.hsId) {
    throw new Error('hsId is required');
  }

  // const accessToken = await currentUser.getIdToken();
  const objectIds = ids.getObjectIds();
  const associationIds = ids.getAssociationIds();

  if (requestData.companyId) {
    associations = [
      {
        to: { id: requestData.companyId },
        types: [
          {
            associationCategory: 'USER_DEFINED',
            associationTypeId: associationIds
              ? associationIds.gen_services_document_to_company
              : null,
          },
        ],
      },
    ];
  } else {
    associations = [
      {
        to: { id: requestData.hsId },
        types: [
          {
            associationCategory: 'USER_DEFINED',
            associationTypeId: associationIds
              ? associationIds.gen_services_document_to_contact
              : null,
          },
        ],
      },
    ];
  }

  if (requestData.contract_id) {
    associations.push({
      to: { id: requestData.contract_id },
      types: [
        {
          associationCategory: 'USER_DEFINED',
          associationTypeId: associationIds
            ? associationIds.gen_services_document_to_contract
            : null,
        },
      ],
    });
  }

  const body = {
    objectType: objectIds ? objectIds.documents : null,
    hsId: requestData.hsId,
    properties: requestData.properties,
    associations: associations,
  };

  const config = {
    url: `${appHelpers.getApiUrl()}/api/v2/documents/createDocument`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: body,
  };

  const { data } = await axios(config);

  return data;
};

export { createDocument };
