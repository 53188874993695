import { AssociationsItem } from '../../types/associations';

import { api } from '../../api/apiHandler';
import { ids } from '../../helpers';
import { FieldValues } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';

interface TicketData {
  fileUrl: string | undefined;
  associations: AssociationsItem | unknown;
  values: FieldValues;
  hsId: string | null | undefined;
}

const useCreateTicket = () => {
  const { getAccessTokenSilently } = useAuth0();

  const createTicket = async (ticketData: TicketData) => {
    const pipelineIds = ids.getPipelineStageIds();

    const requestData = {
      hsId: ticketData.hsId,
      associations: ticketData.associations,
      properties: {
        subject: ticketData.values.ticketSubject,
        content: ticketData.values.ticketContent,
        hs_ticket_category: ticketData.values.category,
        statement_id: ticketData.values.statementId,
        invoice_id: ticketData.values.invoiceId,
        forecast_id: ticketData.values.forecastId,
        hs_pipeline_stage: pipelineIds ? pipelineIds.ticketSubmitted : '',
        ticket_file_attachment: ticketData.fileUrl,
        source_type: 'FORM',
        notify_customer: true,
      },
    };

    try {
      const token = await getAccessTokenSilently();
      const { data } = await api(requestData, token).tickets.createTicket();
      return {
        status: 'success',
        message: 'Query created successfully',
        data,
      };
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw new Error(`Error creating ticket: ${err.message}`);
      } else {
        throw new Error(`Error creating ticket`);
      }
    }
  };

  return { createTicket };
};

export { useCreateTicket };
