import { Loader } from '../../../../components/loader';
import { formatters } from '../../../../helpers';
import { useState } from 'react';
import { QueriesSingleItemProps } from '../types';
import { QueriesSingleInfoWidgetStyled } from '../styles/queriesSingleInfoWidget.styles';
import { useResolveTicket } from '../../../../hooks/tickets/useResolveTicket';

const QueriesSingleInfoWidget = ({ ticket }: QueriesSingleItemProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const { resolveTicket } = useResolveTicket();

  const handleUpdateTicketStatus = async () => {
    setIsClosing(true);
    try {
      await resolveTicket(ticket.id, 'ticketClosed');
    } catch (error) {
      console.error('Error updating ticket status:', error);
    }
    setIsClosing(false);
  };

  return (
    <QueriesSingleInfoWidgetStyled>
      <ul>
        {ticket.estimatedResponseDate && (
          <li>
            <strong>Estimated response date:</strong>{' '}
            {formatters.formatDate(ticket.estimatedResponseDate, 'DD/MM/YY')}
          </li>
        )}
        {ticket.lastActivityDate && (
          <li>
            <strong>Last activity date:</strong>{' '}
            {formatters.formatDate(ticket.lastActivityDate, 'DD/MM/YY - HH:mm')}
          </li>
        )}
        {ticket.lastMessageSentAt && (
          <li>
            <strong>Last response date:</strong>{' '}
            {formatters.formatDate(
              ticket.lastMessageSentAt,
              'DD/MM/YY - HH:mm'
            )}
          </li>
        )}
        {ticket.createdAt && (
          <li>
            <strong>Creation date:</strong>{' '}
            {formatters.formatDate(ticket.createdAt, 'DD/MM/YY - HH:mm')}
          </li>
        )}
        {ticket.closedDate && (
          <li>
            <strong>Closed date:</strong>{' '}
            {formatters.formatDate(ticket.closedDate, 'DD/MM/YY - HH:mm')}
          </li>
        )}
        {ticket.ticketOwner && (
          <li>
            <strong>Team member handling your query:</strong>{' '}
            {ticket.ticketOwner}
          </li>
        )}
      </ul>
      {ticket.pipelineStage !== 'Closed' &&
        (isClosing ? (
          <Loader />
        ) : (
          <button
            className='button button-provider m-t-sm'
            onClick={handleUpdateTicketStatus}
          >
            Mark as resolved
          </button>
        ))}
    </QueriesSingleInfoWidgetStyled>
  );
};

export { QueriesSingleInfoWidget };
