import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';
import { useAuth0 } from '@auth0/auth0-react';

const useGetSingleStatement = () => {
  const { accountId, id } = useParams();
  const { hsId } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const getSingleStatement = async () => {
    if (id) {
      const token = await getAccessTokenSilently();
      return await api(
        {
          hsId: hsId,
          companyId: accountId,
          recordId: id,
        },
        token
      ).statements.getStatement();
    } else {
      return [];
    }
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['StatementSingle', id],
    queryFn: getSingleStatement,
    enabled: !!hsId,
  });

  return {
    data,
    isLoading,
    error,
  };
};

export { useGetSingleStatement };
