import { appHelpers } from '../../../helpers';

import { ids } from '../../../helpers';

import axios from 'axios';
import { INVOICES_PROPERTIES } from '../../../constants/invoicesObjectProperties';

import { AssociationRequestData } from '../../../types/associations';

type SingleStatementInvoicesRequestData = {
  recordId: string;
  hsId: string;
  uid: string;
  currentPage: string | null;
  nextPage: string | null;
};

const getStatementInvoices = async (
  requestData: SingleStatementInvoicesRequestData,
  token: string
) => {
  if (!requestData) {
    throw new Error('Unable to fetch statement');
  }

  const { recordId, hsId, currentPage, nextPage } = requestData;

  const objectIds = ids.getObjectIds();
  const pipelineIds = ids.getPipelineIds();

  const associationRequestData: AssociationRequestData = {
    objectFromType: objectIds ? objectIds.statements : null,
    recordId: recordId,
  };

  const BODY = {
    objectType: objectIds ? objectIds.invoices : null,
    properties: INVOICES_PROPERTIES,
    hsId: hsId,
    pipelineId: pipelineIds ? pipelineIds.invoices : null,
    associationData: associationRequestData,
    pagination: {
      currentPage: currentPage || 1,
      nextPage: nextPage || 2,
      perPage: 20,
    },
  };

  const CONFIG = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/invoices/getInvoices`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: BODY,
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error fetching invoices: ${err.message}`);
    } else {
      throw new Error(`Error fetching invoices`);
    }
  }
};

export { getStatementInvoices };
