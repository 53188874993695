import { api } from '../../api/apiHandler';
import { ids } from '../../helpers';

const createInvoice = async (data, token) => {
  const currentUnixTime = new Date().getTime();

  const attachmentKey = Object.keys(data.values).find((key) =>
    key.startsWith('attachment_')
  );

  const invoiceUrl = await api({
    clientName: `${data.uid}_${data.hsId}`,
    file: data.values[attachmentKey],
    uploadType: 'invoices',
  }).files.uploadFile(); // Doesn't use BE API so doesn't need token

  if (!invoiceUrl) {
    return {
      status: 'error',
      message: 'There was an error uploading your file. Please try again',
    };
  }

  const dataToSend = {
    hsId: data.hsId,
    uid: data.uid,
    companyId: data.companyId,
    properties: {
      reference: `PMi${currentUnixTime}`,
      statement_ref: data.values.statementNumber,
      invoice_number: data.values.invoiceReference,
      net_total: data.values.invoiceTotal,
      submitted_by: `${data.firstname} ${data.lastname}`,
      date: data.values.invoiceDate,
      invoice_attachment_url: invoiceUrl,
      hs_pipeline_stage: ids.getPipelineStageIds().invoiceRecieved,
    },
  };

  const response = await api(dataToSend, token).invoices.createInvoice();

  if (response.status === 200) {
    return { status: 'success', message: 'Invoice created successfully' };
  } else {
    return { status: 'error', message: response.results };
  }
  //
};

export { createInvoice };
