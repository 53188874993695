export const ASSOCIATION_IDS_DEV = {
  gs_invoice_to_contact: 287,
  gs_invoice_to_gen_services_statement: 307,
  gen_services_document_to_contact: 245,
  gen_services_document_to_contract: 219,
  gen_services_document_to_company: 257,
  gen_services_company_to_document: 254,
  gen_services_forecast_asset_to_contact: 299,
  gen_services_forecast_asset_to_contract: 278,
  gen_services_forecast_asset_to_ticket: 236,
  ticket_to_company: 26,
  ticket_to_contact: 16,
  ticket_to_contract: 284,
  gs_statement_to_ticket: 304,
  gen_services_invoice_to_ticket: 298,
  gen_services_invoice_to_company: 219,
  gen_services_invoice_to_contact: 305,
  gen_services_forecast_asset_to_company: 217,
  gs_forecast_asset_to_ticket: 235,
  contact_to_email: 197,
  email_to_contact: 198,
  ticket_to_email: 223,
  email_to_ticket: 224,
  gs_loas_to_companies: 314,
  gs_loas_to_contacts: 316,
};

export const ASSOCIATION_IDS_STAGING = {
  gs_invoice_to_contact: 131,
  gs_invoice_to_gen_services_statement: 285,
  gen_services_document_to_contact: 237,
  gen_services_document_to_contract: 290,
  gen_services_document_to_company: 253,
  gen_services_company_to_document: 254,
  gen_services_forecast_asset_to_contact: 215,
  gen_services_forecast_asset_to_contract: 292,
  gen_services_forecast_asset_to_ticket: 260,
  ticket_to_company: 26,
  ticket_to_contact: 16,
  ticket_to_contract: 284,
  gs_statement_to_ticket: 258,
  gen_services_invoice_to_ticket: 262,
  gen_services_invoice_to_company: 219,
  gen_services_invoice_to_contact: 131,
  gen_services_forecast_asset_to_company: 217,
  gs_forecast_asset_to_ticket: 260,
  contact_to_email: 197,
  email_to_contact: 198,
  ticket_to_email: 223,
  email_to_ticket: 224,
  gs_loas_to_companies: 314,
  gs_loas_to_contacts: 316,
};

export const ASSOCIATION_IDS_PROD = {
  gs_invoice_to_contact: 265,
  gs_invoice_to_gen_services_statement: 269,
  gen_services_document_to_contact: 319,
  gen_services_document_to_contract: 356,
  gen_services_forecast_asset_to_contact: 275,
  gen_services_forecast_asset_to_contract: 358,
  gen_services_document_to_company: 327,
  gen_services_company_to_document: 328,
  gen_services_forecast_asset_to_ticket: 259,
  ticket_to_company: 26,
  ticket_to_contact: 16,
  ticket_to_contract: 366,
  gs_statement_to_ticket: 334,
  gen_services_invoice_to_ticket: 312,
  gen_services_invoice_to_company: 263,
  gen_services_invoice_to_contact: 265,
  gen_services_forecast_asset_to_company: 273,
  contact_to_email: 197,
  ticket_to_email: 223,
  email_to_ticket: 224,
  email_to_contact: 198,
  gs_loas_to_companies: 314,
  gs_loas_to_contacts: 316,
};
