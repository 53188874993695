import axios from 'axios';
import { appHelpers } from '../../../../helpers';
import { ids } from '../../../../helpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadFileToStorage } from '../../../../services/storage';

import { ForecastData } from '../types';
import { generateForecastAssociations } from '../services/generateForecastAssociations';
import { useAuth0 } from '@auth0/auth0-react';

const createForecast = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const handleAddForecast = async (forecastData: ForecastData) => {
    const objectIds = ids.getObjectIds();
    const pipelineIds = ids.getPipelineStageIds();

    const uploadFileData = {
      file: forecastData.attachment_forecastsForm,
      hsId: forecastData.hsId,
      companyId: null,
      uploadType: 'documents',
    };

    const associationData = {
      contractId: forecastData.contractId,
      companyId: null,
      hsId: forecastData.hsId,
    };

    // 1. Upload to Firebase
    const fileUrl = await uploadFileToStorage(uploadFileData);
    const associations = generateForecastAssociations(associationData);
    const forecastProperties = {
      forecast_attachment: fileUrl,
      forecast_from: forecastData.forecast_from,
      forecast_to: forecastData.forecast_to,
      hs_pipeline_stage: pipelineIds ? pipelineIds.forecastSubmitted : null,
      mpxn: forecastData.mpxn,
      site_name: forecastData.site_name,
      submitted_by: forecastData.submitted_by,
      forecast_estimated_volume__mwh_: forecastData.forecastTotal,
    };

    const body = {
      objectType: objectIds ? objectIds.forecasts : null,
      hsId: forecastData.hsId,
      properties: forecastProperties,
      associations: associations,
    };

    const token = await getAccessTokenSilently();

    const config = {
      url: `${appHelpers.getApiUrl()}/api/v2/forecasts/createForecast`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: body,
    };

    try {
      const { data } = await axios(config);

      return {
        status: 'success',
        message: 'Document uploaded',
        data,
      };
    } catch (err) {
      console.error(err);
      return {
        status: 'error',
        message:
          'There was a problem uploading your document, please try again',
      };
    }
  };

  const mutation = useMutation({
    mutationFn: handleAddForecast,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['forecasts'] });
    },
  });

  const handleCreateForecast = (forecastData: ForecastData) => {
    mutation.mutate(forecastData);
  };

  return { handleCreateForecast };
};

export { createForecast };
