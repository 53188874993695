import { appHelpers } from '../../../helpers';
import axios from 'axios';

const updateTicket = async (requestData, token) => {
  let config = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/tickets/updateTicket`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: requestData,
  };

  return await axios(config);
};

export { updateTicket };
