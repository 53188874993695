import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';
import { useAuth0 } from '@auth0/auth0-react';

const useGetDocuments = (
  searchTerm = '',
  submittedStart = '',
  submittedEnd = ''
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { hsId } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: documentsData,
    error: isDocumentsError,
    isLoading: isDocumentsLoading,
    refetch,
    isFetching: isFetchingdocuments,
  } = useQuery(
    ['documents', currentPage, searchTerm, submittedStart, submittedEnd],
    async () => {
      if (hsId) {
        const token = await getAccessTokenSilently();
        if (searchTerm) {
          return await api(
            {
              objectName: 'documents',
              searchValue: searchTerm,
              hsId: hsId,
              submittedStart: submittedStart,
              submittedEnd: submittedEnd,
            },
            token
          ).documents.searchDocuments();
        } else if (submittedStart || submittedEnd) {
          return await api(
            {
              objectName: 'documents',
              searchValue: searchTerm,
              hsId: hsId,
              submittedStart: submittedStart,
              submittedEnd: submittedEnd,
            },
            token
          ).documents.searchDocuments();
        } else {
          return await api(
            {
              hsId: hsId,
              currentPage: currentPage,
              nextPage: currentPage + 1,
            },
            token
          ).documents.getDocuments();
        }
      } else {
        return [];
      }
    },
    { enabled: !!hsId, keepPreviousData: true }
  );

  return {
    documentsData,
    isDocumentsError,
    isDocumentsLoading,
    refetch,
    isFetchingdocuments,
    setCurrentPage,
    currentPage,
  };
};

export { useGetDocuments };
