import { appHelpers } from '../../../helpers';
import axios from 'axios';

const getAppSettings = async (_: unknown, token: string) => {
  const CONFIG = {
    method: 'GET',
    url: `${appHelpers.getApiUrl()}/api/v2/app/getAppSettings`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    throw new Error(`Error fetching settings`);
  }
};

export { getAppSettings };
