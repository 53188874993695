import axios from 'axios';
import { appHelpers } from '../../../helpers';

const getMessages = async (uid: string, token: string) => {
  if (!uid) {
    throw new Error('Unable to fetch messages');
  }

  const CONFIG = {
    method: 'GET',
    url: `${appHelpers.getApiUrl()}/api/v2/messages/getMessages?hsId=${uid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = await axios(CONFIG);

    return data.results;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error fetching messages: ${err.message}`);
    } else {
      throw new Error(`Error fetching messages`);
    }
  }
};

export { getMessages };
