import { FieldValues, useForm, Controller } from 'react-hook-form';
import { AddQueryInvoices } from './addQueryInvoices';
import { FileUpload } from '../fileUpload';
import Select from 'react-select';

// Hooks
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../../providers/user/useUserContext';
import { useUploadFile } from '../../../hooks/files/useUploadFile';
import { useAssociateTicket } from '../../../hooks/tickets/useAssociateTicket';
import { useCreateTicket } from '../../../hooks/tickets/useCreateTicket';
import { useGetCompanyMembers } from '../../../hooks/companies/useGetCompanyMembers';

// Constants
import { TICKET_FORM_CATEGORIES } from '../../../constants/ticketCategories';
import FormSuccess from '../formSuccess/formSuccess';
import { FormSending } from '../formSending';
import { AddQueryStatements } from './addQueryStatements';
import { AddQueryForecasts } from './addQueryForecasts';
import { AddQueryAll } from './addQueryAll';
import { AddQueryContractsDropdown } from './dropdowns';

// Types
interface QueryItem {
  invoiceNumber?: string;
  id?: string | undefined;
  statementNumber?: string;
  mpxn?: string;
}

type AddQueryProps = {
  queryType: string;
  queryItem?: QueryItem;
  setModalActiveState?: () => void;
  onSentCallback?: () => void;
};

const AddQuery = ({
  queryType,
  queryItem,
  setModalActiveState,
  onSentCallback,
}: AddQueryProps) => {
  const [formStatus, setFormStatus] = useState('start');
  const [addQueryType, setAddQueryType] = useState<string>();
  const formCategories = TICKET_FORM_CATEGORIES[queryType];
  const { handleSubmit, register, setValue, reset, control } = useForm();
  const { uploading, handleUploadFile } = useUploadFile();
  const { hsId } = useUserContext();
  const { accountId } = useParams();
  const { createTicket } = useCreateTicket();

  const { data } = useGetCompanyMembers();

  const handleAddQuery = async (values: FieldValues) => {
    setFormStatus('sending');

    // Get The File
    let attachmentKey: string | number | undefined;
    if (typeof values === 'object' && values !== null) {
      attachmentKey = Object.keys(values).find((key) =>
        key.startsWith('attachment_')
      );
    }

    let file: File;
    let fileUrl: string | undefined;
    if (attachmentKey !== undefined && hsId) {
      file = values[attachmentKey] as File;
      fileUrl = await handleUploadFile(file, hsId, 'tickets');
    }

    const addQueryObjectType = queryType === 'all' ? addQueryType : queryType;

    const associations = useAssociateTicket({
      values,
      hsId,
      type: addQueryObjectType,
      companyId: accountId,
    });

    const ticketData = {
      fileUrl,
      associations: associations,
      values,
      hsId,
    };

    try {
      await createTicket(ticketData);
      setFormStatus('start');
      reset();

      if (onSentCallback) {
        setFormStatus('sent');
        onSentCallback();
      }

      if (setModalActiveState) {
        setModalActiveState();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {formStatus === 'start' && (
        <>
          <h2>Raise Query</h2>
          <form onSubmit={handleSubmit(handleAddQuery)}>
            <div className='form-group'>
              {data?.results && (
                <>
                  <div className='select-wrapper'>
                    <Controller
                      name='assignee'
                      key={1}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={data.results.map(
                            (option: {
                              id: string;
                              firstname: string;
                              lastname: string;
                            }) => ({
                              value: option.id,
                              label: option.firstname + ' ' + option.lastname,
                            })
                          )}
                          className='react-select-container'
                          classNamePrefix='react-select'
                          placeholder='Tag other colleagues'
                          isClearable
                          isMulti
                          aria-label='Tag other colleagues'
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </div>
            <div className='form-group'>
              <label htmlFor='ticketSubject'>Query subject</label>
              <input
                id='ticketSubject'
                className='form-control'
                {...register('ticketSubject')}
                placeholder='Provide a title for your query'
              />
            </div>

            <AddQueryContractsDropdown register={register} />

            {queryType === 'all' && (
              <AddQueryAll
                categories={formCategories}
                register={register}
                addQueryType={addQueryType}
                setAddQueryType={setAddQueryType}
              />
            )}
            {queryItem && queryType === 'forecasts' && (
              <AddQueryForecasts
                categories={formCategories}
                register={register}
                recordNumber={queryItem.id}
                pmRef={queryItem.mpxn}
              />
            )}
            {queryItem && queryType === 'invoices' && (
              <AddQueryInvoices
                categories={formCategories}
                register={register}
                invoiceNumber={queryItem.id}
              />
            )}
            {queryItem && queryType === 'statements' && (
              <AddQueryStatements
                categories={formCategories}
                register={register}
                recordNumber={queryItem.id}
                pmRef={queryItem.statementNumber}
              />
            )}
            <div className='form-group'>
              <label htmlFor='ticketContent'>Message</label>
              <textarea
                id='ticketContent'
                className='form-control'
                {...register('ticketContent')}
                placeholder='Message'
              />
            </div>
            {uploading ? (
              <>
                <p>Uploading file</p>
              </>
            ) : (
              <FileUpload setValue={setValue} id={queryItem?.id} />
            )}

            <div className='form-group'>
              <input
                className='button button--full button-primary--blue'
                type='submit'
                value='Submit'
              />
            </div>
          </form>
        </>
      )}

      {formStatus === 'sending' && (
        <FormSending
          title='Submitting query'
          message='Please wait whilst we submit your query'
        />
      )}

      {formStatus === 'sent' && (
        <FormSuccess title='Success' message='Your query has been submitted' />
      )}
    </>
  );
};

export { AddQuery };
