import { DOCS_PROPERTIES } from '../../../constants/docsObjectProperties';

import { appHelpers } from '../../../helpers';
import { ids } from '../../../helpers';

import axios from 'axios';

import { AssociationRequestData, DocsRequestData } from './types';

const getDocuments = async (
  docsRequestData: DocsRequestData,
  token: string
) => {
  const { hsId, companyId, uid, currentPage, nextPage } = docsRequestData;

  const associationRequestData: AssociationRequestData = {
    objectFromType: null,
    recordId: null,
  };

  const objectIds = ids.getObjectIds();

  if (companyId) {
    associationRequestData.objectFromType = objectIds
      ? objectIds.companies
      : null;
    associationRequestData.recordId = companyId;
  } else {
    associationRequestData.objectFromType = objectIds
      ? objectIds.contacts
      : null;
    associationRequestData.recordId = hsId;
  }

  const body = {
    objectType: objectIds ? objectIds.documents : null,
    properties: DOCS_PROPERTIES,
    hsId: hsId,
    uid: uid,
    companyId: companyId,
    associationData: associationRequestData,
    pagination: {
      currentPage: currentPage || 0,
      nextPage: nextPage || 1,
      perPage: 20,
    },
  };

  const config = {
    url: `${appHelpers.getApiUrl()}/api/v2/documents/getDocuments`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'post',
    data: body,
  };

  try {
    const { data } = await axios(config);

    return data;
  } catch (err) {
    console.error(err);
  }
};

export { getDocuments };
