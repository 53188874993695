import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';
import { useAuth0 } from '@auth0/auth0-react';

const useGetInvoices = (
  searchTerm = '',
  submittedStart = '',
  submittedEnd = ''
) => {
  const [currentInvoicesPage, setCurrentInvoicesPage] = useState(1);
  const { hsId } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: invoicesData,
    isLoading: isInvoicesLoading,
    error: isInvoicesError,
    refetch: invoiceRefetch,
    isFetching: isFetchingInvoices,
  } = useQuery(
    ['invoices', currentInvoicesPage, searchTerm, submittedStart, submittedEnd],
    async () => {
      if (hsId) {
        const token = await getAccessTokenSilently();
        if (searchTerm) {
          return await api(
            {
              objectName: 'invoices',
              searchValue: searchTerm,
              hsId: hsId,
              submittedStart: submittedStart,
              submittedEnd: submittedEnd,
            },
            token
          ).invoices.searchInvoices();
        } else if (submittedStart || submittedEnd) {
          return await api(
            {
              objectName: 'invoices',
              searchValue: searchTerm,
              hsId: hsId,
              submittedStart: submittedStart,
              submittedEnd: submittedEnd,
            },
            token
          ).invoices.searchInvoices();
        } else {
          return await api(
            {
              hsId: hsId,
              currentPage: currentInvoicesPage,
              nextPage: currentInvoicesPage + 1,
            },
            token
          ).invoices.getInvoices();
        }
      } else {
        return [];
      }
    },
    { enabled: !!hsId, keepPreviousData: true }
  );

  return {
    invoicesData,
    isInvoicesLoading,
    isInvoicesError,
    invoiceRefetch,
    isFetchingInvoices,
    currentInvoicesPage,
    setCurrentInvoicesPage,
  };
};

export { useGetInvoices };
