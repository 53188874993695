import axios from 'axios';
import { appHelpers } from '../../../helpers';
// import { ids } from '../../../helpers';

type UpdateData<T extends Record<string, unknown>> = {
  properties: T;
  recordId: string;
  objectId: string;
};

const updateCustomObject = async <T extends Record<string, unknown>>(
  updateData: UpdateData<T>,
  token: string
) => {
  if (!updateData) {
    throw new Error('Properties data is missing');
  }

  const CONFIG = {
    url: `${appHelpers.getApiUrl()}/api/v2/customObjects/updateCustomObject`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: updateData,
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error updating record`);
    } else {
      throw new Error(`Error updating record`);
    }
  }
};

export { updateCustomObject };
