import { appHelpers } from '../../../helpers';
import { TICKETS_PROPERTIES } from '../../../constants/ticketObjectProperties';
import { ids } from '../../../helpers';

import axios from 'axios';

import { GetTicketsRequest, ObjectIds } from './types';
import { AssociationRequestData } from '../../../types/associations';

const getTickets = async (requestData: GetTicketsRequest, token: string) => {
  if (!requestData) {
    throw new Error('Unable to fetch invoice');
  }

  const { objectType, hsId, recordId, currentPage = 1, nextPage } = requestData;

  const associationRequestData: AssociationRequestData = {
    objectFromType: null,
    recordId: null,
  };

  const objectIds: ObjectIds | null = ids.getObjectIds();
  const pipelineIds = ids.getPipelineIds();

  let objectFromType: string | null = null;

  if (objectIds && objectType) {
    objectFromType = objectIds[objectType];
  }

  if (recordId) {
    associationRequestData.objectFromType = objectFromType;
    associationRequestData.recordId = recordId;
  } else {
    associationRequestData.objectFromType = objectIds
      ? objectIds.contacts
      : null;
    associationRequestData.recordId = hsId;
  }

  const BODY = {
    objectType: objectIds ? objectIds.tickets : null,
    properties: TICKETS_PROPERTIES,
    hsId: hsId,
    pipelineId: pipelineIds ? pipelineIds.tickets : null,
    associationData: associationRequestData,
    pagination: {
      currentPage: currentPage || 0,
      nextPage: nextPage || 1,
      perPage: 20,
    },
  };

  const CONFIG = {
    url: `${appHelpers.getApiUrl()}/api/v2/tickets/getTickets`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: BODY,
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    console.log(err);
  }
};

export { getTickets };
