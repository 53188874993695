import axios from 'axios';
import { appHelpers } from '../../../helpers';

const verifyFirebaseUser = async (email: string, token: string) => {
  if (!email) {
    throw new Error('Email address is required');
  }

  const BODY = {
    email,
  };

  const CONFIG = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/login/verifyUser`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: BODY,
  };

  const { data } = await axios(CONFIG);

  return data;
};

export { verifyFirebaseUser };
