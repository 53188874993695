import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';

import { useParams } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';

const useGetStatementInvoices = () => {
  const { hsId } = useUserContext();
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const { data, refetch } = useQuery(['Statement Invoices', id], async () => {
    if (hsId) {
      const token = await getAccessTokenSilently();
      return await api(
        {
          hsId: hsId,
          recordId: id,
        },
        token
      ).statements.getStatementInvoices();
    } else {
      return [];
    }
  });

  return { invoices: data, refetchStatements: refetch };
};

export { useGetStatementInvoices };
