import { appHelpers } from '../../../helpers';
import { STATEMENT_PROPERTIES } from '../../../constants/statementObjectProperties';
import { ids } from '../../../helpers';

import axios from 'axios';

type SingleStatementRequestData = {
  recordId: string;
  hsId: string;
};

const getStatement = async (
  requestData: SingleStatementRequestData,
  token: string
) => {
  if (!requestData) {
    throw new Error('Unable to fetch statement');
  }

  const { recordId, hsId } = requestData;

  const objectIds = ids.getObjectIds();
  const objectId = objectIds ? objectIds.statements : '';
  const pipelineId = ids.getPipelineIds()
    ? ids.getPipelineIds()?.statements
    : '';

  let propertiesString = '';
  STATEMENT_PROPERTIES.forEach((property) => {
    propertiesString += `${property},`;
  });

  const REQUEST_URL = `${appHelpers.getApiUrl()}/api/v2/statements/getStatements/${recordId}?object=${objectId}&hsId=${hsId}&properties=${propertiesString}&pipeline=${pipelineId}`;

  const CONFIG = {
    url: REQUEST_URL,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error fetching statement: ${err.message}`);
    } else {
      throw new Error(`Error fetching statement`);
    }
  }
};

export { getStatement };
