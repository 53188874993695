import { useParams } from 'react-router';
import { api } from '../../api/apiHandler';
import { useQuery } from '@tanstack/react-query';
import { useUserContext } from '../../providers/user/useUserContext';
import { useAuth0 } from '@auth0/auth0-react';

const useGetForecast = () => {
  const { id, accountId } = useParams();
  const { hsId } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading, error } = useQuery(
    ['ForecastSingle', id],
    async () => {
      if (id) {
        const token = await getAccessTokenSilently();
        return await api(
          {
            hsId: hsId,
            companyId: accountId,
            recordId: id,
          },
          token
        ).forecasts.getForecast();
      } else {
        return [];
      }
    },
    { enabled: !!hsId }
  );

  return {
    data,
    isLoading,
    error,
  };
};

export { useGetForecast };
