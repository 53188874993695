import axios from 'axios';
import { appHelpers } from '../../../helpers';
const updateFirestoreUser = async (userData, token) => {
  const config = {
    method: 'POST',
    url: `${appHelpers.getApiUrl()}/api/v2/users/updateFirestoreUser`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: userData,
  };

  try {
    return await axios(config);
  } catch (err) {
    return { status: 'error', message: err };
  }
};

export { updateFirestoreUser };
