import { INVOICES_PROPERTIES } from '../../../constants/invoicesObjectProperties';
import { appHelpers, ids } from '../../../helpers';

import axios from 'axios';

type SingleInvoiceRequestData = {
  invoiceId: string;
  hsId: string;
};

const getInvoice = async (
  requestData: SingleInvoiceRequestData,
  token: string
) => {
  if (!requestData) {
    throw new Error('Unable to fetch invoice');
  }

  const { invoiceId, hsId } = requestData;
  const objectIds = ids.getObjectIds();
  const objectId = objectIds ? objectIds.invoices : '';
  const pipelineId = ids.getPipelineIds() ? ids.getPipelineIds()?.invoices : '';

  let invoicePropertiesString = '';
  INVOICES_PROPERTIES.forEach((property) => {
    invoicePropertiesString += `${property},`;
  });

  const REQUEST_URL = `${appHelpers.getApiUrl()}/api/v2/invoices/getInvoices/${invoiceId}?object=${objectId}&hsId=${hsId}&properties=${invoicePropertiesString}&pipeline=${pipelineId}`;

  const CONFIG = {
    method: 'GET',
    url: REQUEST_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = await axios(CONFIG);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Error fetching invoice: ${err.message}`);
    } else {
      throw new Error(`Error fetching invoice`);
    }
  }
};

export { getInvoice };
