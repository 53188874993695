import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { api } from '../../api/apiHandler';
import { useUserContext } from '../../providers/user/useUserContext';
import { useAuth0 } from '@auth0/auth0-react';

const useGetTicket = () => {
  const { id } = useParams();
  const { hsId } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading, error } = useQuery({
    queryKey: ['ticket', id],
    queryFn: async () => {
      if (id) {
        const token = await getAccessTokenSilently();
        return await api(
          {
            ticketId: id,
            hsId: hsId,
          },
          token
        ).tickets.getSingleTicket();
      } else {
        return [];
      }
    },
    enabled: !!hsId,
  });

  return {
    singleTicketData: data,
    singleTicketError: error,
    singleTicketLoading: isLoading,
  };
};

export { useGetTicket };
