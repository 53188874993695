import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../api/apiHandler';

import { useUserContext } from '../../providers/user/useUserContext';
import { useParams } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';

const useGetStatements = (
  searchTerm = '',
  submittedStart = '',
  submittedEnd = ''
) => {
  const { accountId } = useParams();
  const { hsId } = useUserContext();
  const [currentPage, setCurrentPage] = useState(1);
  const { getAccessTokenSilently } = useAuth0();

  const { data, error, isLoading, refetch, isFetching } = useQuery(
    ['statements', currentPage, searchTerm, submittedStart, submittedEnd],
    async () => {
      if (hsId) {
        const token = await getAccessTokenSilently();
        if (searchTerm) {
          return await api(
            {
              objectName: 'statements',
              searchValue: searchTerm,
              hsId: hsId,
              submittedStart: submittedStart,
              submittedEnd: submittedEnd,
            },
            token
          ).statements.searchStatements();
        } else if (submittedStart || submittedEnd) {
          return await api(
            {
              objectName: 'statements',
              searchValue: searchTerm,
              hsId: hsId,
              submittedStart: submittedStart,
              submittedEnd: submittedEnd,
            },
            token
          ).statements.searchStatements();
        } else {
          return await api(
            {
              hsId: hsId,
              companyId: accountId,
              currentPage: currentPage,
              nextPage: currentPage + 1,
            },
            token
          ).statements.getStatements();
        }
      } else {
        return [];
      }
    },
    { enabled: !!hsId, keepPreviousData: true }
  );

  return {
    statementsData: data,
    isStatementsLoading: isLoading,
    statementsError: error,
    statementsRefetch: refetch,
    statementsFetching: isFetching,
    setCurrentPage,
    currentPage,
  };
};

export { useGetStatements };
