import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useUserContext } from '../../../providers/user/useUserContext';
import { api } from '../../../api/apiHandler';
import { UserFormValues } from '../types';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useUpdateUser = () => {
  const [formStatus, setFormStatus] = useState('idle');

  const queryClient = useQueryClient();
  const { hsId } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const updateUser = async (userUpdateData: UserFormValues) => {
    const userData = {
      properties: {
        firstname: userUpdateData.firstName,
        lastname: userUpdateData.lastName,
        jobtitle: userUpdateData.jobTitle,
      },
      hsId: hsId,
    };

    try {
      setFormStatus('saving');
      const token = await getAccessTokenSilently();

      await api(userData, token).users.updateHubspotUser();
      setFormStatus('success');
    } catch (err) {
      console.error(err);
    }
  };

  const mutation = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
  });

  const handleUpdateUser = (userUpdateData: UserFormValues) => {
    mutation.mutate(userUpdateData);
  };

  return { handleUpdateUser, formStatus };
};

export { useUpdateUser };
