import { useParams } from 'react-router';
import { api } from '../../api/apiHandler';
import { useQuery } from '@tanstack/react-query';

import { useUserContext } from '../../providers/user/useUserContext';
import { useAuth0 } from '@auth0/auth0-react';

const useGetSingleInvoice = () => {
  const { id } = useParams();
  const { hsId } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading, error } = useQuery({
    queryKey: ['invoice', id],
    queryFn: async () => {
      if (hsId) {
        const token = await getAccessTokenSilently();
        return await api(
          {
            invoiceId: id,
            hsId: hsId,
          },
          token
        ).invoices.getInvoice();
      } else {
        return [];
      }
    },
    enabled: !!hsId,
  });

  return {
    singleInvoiceData: data,
    singleInvoiceError: error,
    singleInvoiceLoading: isLoading,
  };
};

export { useGetSingleInvoice };
